import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { Hero } from 'app/components/Hero'
import { Info } from 'app/components/Info'
import { Intro } from 'app/components/Intro'
import { PlaceSlider } from 'app/components/PlaceSlider'
import { SEO } from 'app/components/SEO'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { Tris } from 'app/components/Tris'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import { PageProps } from 'gatsby'
import React, { memo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function ThermalParkPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          pageID={context.id}
          {...context.headerProps}
        />
      ) : null}
      {context.heroProps ? (
        <Hero {...context.heroProps} languagePrefix={context.languagePrefix} />
      ) : null}
      <div className="wrapper">
        <img src="/arc_hero.svg" alt="" />
        {context.introProps ? (
          <div className="background">
            <div className="background__color">
              <Intro {...context.introProps} />
            </div>
          </div>
        ) : null}
        {context.placeSliderProps ? (
          <div className="background">
            <div className="background__color">
              <PlaceSlider {...context.placeSliderProps} />
            </div>
          </div>
        ) : null}
        {context.trisProps ? (
          <div className="background-light">
            <img
              src="/arc_top_light.svg"
              alt=""
              style={{ backgroundColor: '#ffffff' }}
            />
            <div className="background-light__color">
              <Tris variant="capitalLetter" {...context.trisProps} />
            </div>
          </div>
        ) : null}
        {context.infoProps ? (
          <div className="background">
            <img
              src="/arc_top.svg"
              alt=""
              style={{ backgroundColor: '#F3EFEA' }}
            />
            <div className="background__color">
              <Info {...context.infoProps} />
            </div>
          </div>
        ) : null}
        {context.bannerCTAProps ? (
          <BannerCTA {...context.bannerCTAProps} />
        ) : null}
        {context.footerProps ? (
          <Footer
            languageCode={context.languageCode}
            {...context.footerProps}
          />
        ) : null}
      </div>
    </Container>
  )
})

const Container = styled.main``
